import qs from 'qs';
import { createBrowserHistory } from 'history';
import { observable, computed, extendObservable } from 'mobx';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import UIStore from './uiStore';
import { debugLog } from './utils';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {
	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	currentConvocation = null;

	@observable noMap = false;

	adminPath = '/admin';
	subscribers = [];

	constructor() {
		this.history = createBrowserHistory();
		this.ui = new UIStore(this);

		this.local = new LocalStore();

		this.local.extend({
			updateChat: true,
			connectToSip: true,
			listConfig: this.listConfig,
			sessionDebug: true,
			regLog: false,
			showDebugInfo: false,
			autoLogout: false,
			autoLogoutDelay: 10,
			showOfflineMembers: false,
			acceptCookie: false,
		});

		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
		});

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});

		this.debugLog = debugLog;
	}

	init = async () => {
		await this.model.connect();
		await this.ui.init();
		/*
		Object.defineProperty(this.model.SessionQuestion.prototype, 'stage', {
			get: function() {
				return new Promise((resolve, reject) => {
					this.stages.find({ order: 'id desc', limit: 1 })
						.then(stages => {
							resolve(stages.length > 0 ? stages[0] : null)
						})
						.catch(reject);
				});
			}
		});
*/
	};

	subscribeOnHMR = callback => {
		this.subscribers.push(callback);
	};

	unsubscribeOnHMR = callback => {
		const index = this.subscribers.findIndex(cb => cb === callback);
		if (index !== -1) this.subscribers.splice(index, 1);
	};

	fireHMR = () => {
		console.log('HMR!');
		this.subscribers.forEach(cb => cb());
		// this.clearAllTimers();
	};

	clearAllTimers = () => {
		console.log('clearing all timers');

		let id = window.setTimeout(function() {}, 0);
		while (id--) {
			console.log('id', id);
			window.clearTimeout(id);
		}
	};
}

window.APP_STORE = new AppStore();

export default window.APP_STORE;
