import React from 'react';
import { observable } from 'mobx';
import './cookie-message.scss';
import store from '../../store';
import { observer } from 'mobx-react';

@observer
export default class CookieMessage extends React.Component {
	@observable showMessage = true;
	constructor(props) {
		super(props);
		if (store.local.acceptCookie) {
			this.showMessage = false;
		}
	}

	onAccept = () => {
		store.local.acceptCookie = true;
		store.local.save();
		this.showMessage = false;
	};

	render() {
		if (!this.showMessage) return null;

		return (
			<div className="cookie-popup">
				<div className="cookie-popup-inner">
					<div className="cookie-popup-left">
						<div className="cookie-popup-headline">
							Мы используем файлы cookie для персонализации контента, предоставления функций авторизации и анализа трафика. Чтобы
							отключить файлы cookie, необходимо перейти в настройки веб-браузера. Однако это может ограничить работу с порталом.
						</div>
						<div className="cookie-popup-sub-headline">
							Пользуясь данным интернет ресурсом, свободно своей волей и в своих интересах пользователь даёт согласие на
							автоматизированную, а также без использования средств автоматизации обработку, в т.ч. обработку своих персональных данных,
							передачу и обработку данных о посетителе (а именно IP-адрес, предполагаемое географическое положение, версия браузера,
							разрешение дисплея, версия операционной системы и вспомогательного программного обеспечения, модель устройства, ресурсы,
							поисковые системы, фразы, баннеры, с которых был переход на сайт, список посещённых страниц и проведённое время на сайте),
							а именно - совершение действий, предусмотренных 152-ФЗ «О персональных данных». Если посетитель не согласен с тем, чтобы
							интернет-ресурс осуществлял автоматизированную обработку данных о посетителе, он может соответствующим образом установить
							настройки своего браузера или покинуть сайт.
						</div>
					</div>
					<div className="cookie-popup-right">
						<p className="cookie-popup-accept-cookies" onClick={this.onAccept}>
							Подтверждаю
						</p>
					</div>
				</div>
			</div>
		);
	}
}
